














import { Watch, Component, Vue } from "vue-property-decorator";
import { FileManager } from "uknow-react-components";
import TopBar from "@/components/common/TopBar.vue";
import store from "../../store";
import { getFolderGuid } from "../../helper/utils";
import mainAuth from "@/auth";

@Component({
  components: { TopBar, FileManager },
})
export default class Dokumente extends Vue {
  get auth() {
    if (localStorage.getItem("msToken") == null) {
      return localStorage.getItem("auth")!.split(" ")[1];
    }
    return null;
  }

  async accessToken() {
    if (localStorage.getItem("msToken") == null) return undefined;
    await mainAuth().signIn();
    return mainAuth().accessToken;
  }

  get vourusUrl() {
    return store.state.vourusUrl;
  }

  get username() {
    if (localStorage.getItem("msToken") == null) {
      return atob(this.auth!).split(":")[0];
    }
    return null;
  }

  get selectedClient() {
    return store.getters.selectedClient;
  }

  get folderGuid() {
    return store.getters.folderGuid;
  }

  mounted() {
    this.reloadFolderGuid();
  }

  @Watch("selectedClient")
  updateSelectedClient(val: any, oldVal: any) {
    this.reloadFolderGuid();
  }

  reloadFolderGuid() {
    getFolderGuid();
  }
}
